import * as React from "react";
import type { SVGProps } from "react";
const SvgUserIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="top-graph-02"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill="currentcolor"
      d="M16 .932C7.727.932 1 7.687 1 15.995c0 3.157.971 6.084 2.632 8.507.167.24.345.472.533.713 2.747 3.545 7.03 5.853 11.835 5.853s9.088-2.297 11.835-5.853c.178-.23.366-.462.533-.713A15 15 0 0 0 31 15.995C31 7.687 24.273.932 16 .932m0 29.391c-4.753 0-8.952-2.35-11.553-5.947 1.515-3.598 5.965-6.23 11.24-6.336.104 0 .209.032.313.032s.209-.021.313-.032c5.275.105 9.725 2.738 11.24 6.336-2.59 3.598-6.8 5.947-11.553 5.947m0-13.027h-.313a4.45 4.45 0 0 1-4.137-4.437c0-2.36 1.995-4.469 4.45-4.469s4.45 2.003 4.45 4.469-1.828 4.269-4.137 4.437zm12.023 6.388c-1.546-3.179-5.191-5.57-9.652-6.21 1.661-.87 2.81-2.591 2.81-4.594 0-2.875-2.319-5.203-5.181-5.203s-5.181 2.328-5.181 5.203c0 2.013 1.149 3.734 2.81 4.594-4.46.64-8.106 3.031-9.652 6.21a14.3 14.3 0 0 1-2.246-7.69C1.731 8.097 8.134 1.667 16 1.667s14.269 6.43 14.269 14.329c0 2.832-.836 5.465-2.246 7.688Z"
    />
  </svg>
);
export default SvgUserIcon;
