import * as React from "react";
import type { SVGProps } from "react";
const SvgCartIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="top-graph-02"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill="currentcolor"
      fillRule="evenodd"
      d="M26.736 7.589a1.126 1.126 0 0 1 1.14 1.094v.002l1.376 20.488v.019c.006.296-.11.58-.32.795-.213.22-.512.345-.82.345H3.888c-.31 0-.608-.125-.821-.345a1.1 1.1 0 0 1-.32-.793l1.375-20.53A1.123 1.123 0 0 1 5.264 7.59h5.341v2.642c-.48.156-.831.584-.831 1.107 0 .188.044.365.123.522.198.391.61.658 1.082.658.47 0 .88-.27 1.078-.657.08-.158.126-.336.126-.523 0-.523-.352-.95-.831-1.107V7.589h9.968v2.642c-.48.156-.831.584-.831 1.107 0 .188.044.365.123.522.197.391.61.658 1.081.658.47 0 .88-.27 1.079-.657.08-.158.126-.336.126-.523 0-.523-.352-.95-.832-1.107V7.589zm-5.427-.742v-.355a4.8 4.8 0 0 0-.316-1.645c-.709-1.844-2.529-3.164-4.652-3.168h-.01c-2.13 0-3.956 1.317-4.664 3.169a4.8 4.8 0 0 0-.315 1.644v.063h-.01v.293zm-10.704-.355h.011a5.5 5.5 0 0 1 .372-1.937c.82-2.11 2.9-3.613 5.343-3.618h.01c2.83 0 5.177 2.012 5.642 4.652q.078.442.083.903v.355h4.67c1.013 0 1.855.794 1.887 1.786l1.375 20.508v.005c.02.53-.187 1.01-.534 1.358a1.9 1.9 0 0 1-1.353.559H3.89a1.9 1.9 0 0 1-1.351-.56A1.82 1.82 0 0 1 2 29.164v-.024L3.377 8.633v-.019a1.875 1.875 0 0 1 1.887-1.767h5.341z"
    />
  </svg>
);
export default SvgCartIcon;
